import config from '../config';
import getOuterBounds from './getOuterBounds';
import cityMapping from '../../static/mapping.json';
import getPolygonPathsFromFeatures from './getPolygonPathsFromFeatures';
import setPathsOnPolygons from './setPathsOnPolygons';

export default (globals, mappingName) => {
  const {innerPolygons, outerPolygons, map} = globals;
  const fileName = `${cityMapping[mappingName]}.json`;
  fetch(config.citiesEndpoint + fileName)
  .then(raw => raw.json())
  .then(json => {
    const innerFeatures = json.features[0];
    const outerFeatures = json.features[1];
    const innerPolygonPaths = getPolygonPathsFromFeatures(innerFeatures);
    const outerPolygonPaths = getPolygonPathsFromFeatures(outerFeatures);

    setPathsOnPolygons(innerPolygons, innerPolygonPaths, map, 'INNER');
    setPathsOnPolygons(outerPolygons, outerPolygonPaths, map, 'OUTER');

    const outerBounds = getOuterBounds(outerPolygonPaths, innerPolygonPaths);
    map.fitBounds(outerBounds);
  });
};
