export default {
  mapOptions: {
    mapId: '1e9c73b8f4bb1fb0',
    center: {lat: 50, lng: 11},
    zoom: 6,
    gestureHandling: 'greedy',
    disableDefaultUI: true,
    zoomControl: true
  },
  numberSuggestions: 5,
  placeholder: 'Dortmund',
  fuseSettings: {
    useExtendedSearch: true
  },
  citiesEndpoint: 'https://15km.ubilabs.net/buffered-communities/',
  polygonStyles: {
    inner: {
      strokeColor: '#174b73',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#1E649A',
      fillOpacity: 0.55
    },
    outer: {
      strokeColor: '#174b73',
      strokeOpacity: 0.8,
      strokeWeight: 0,
      fillColor: '#1E649A',
      fillOpacity: 0.35
    }
  }
};
