import config from './config';
import cityMapping from '../static/mapping.json';
import Fuse from 'fuse.js';
import searchForAddressInput from './scripts/searchForAddressInput';

window.initMap = () => {
  const map = new google.maps.Map(document.getElementById('map'), config.mapOptions);

  const innerPolygons = [];
  const outerPolygons = [];

  const fuse = new Fuse(Object.keys(cityMapping), config.fuseSettings);

  const globals = {
    map, innerPolygons, outerPolygons, fuse
  };

  const addressInput = document.querySelector('#address-input');
  const addressForm = document.querySelector('#address-form');
  const datalist = document.querySelector('#suggestions');
  const hiddenPart = document.querySelector('#hidden-part');
  const visiblePart = document.querySelector('#visible-part');

  const queryString = window.location.search;
  const wohnort = new URLSearchParams(queryString).get('wohnort');
  if (wohnort) {addressInput.value = wohnort; searchForAddressInput(globals);}

  addressInput.oninput = () => {
    const suggestions = fuse.search(`^"${addressInput.value}"`);
    datalist.innerHTML = '';
    if (addressInput.value.length === 0) {
      hiddenPart.innerHTML = '';
      visiblePart.innerHTML = '';
    }
    const possibleSuggestionEntered = suggestions.every(suggestion => suggestion.item.toLowerCase() !== addressInput.value.toLowerCase());
    if (possibleSuggestionEntered) {
      const topSuggestion = suggestions[0];
      if (topSuggestion) {
        hiddenPart.innerHTML =
          addressInput.value;
        visiblePart.innerHTML = topSuggestion.item.slice(addressInput.value.length, topSuggestion.item.length);
      } else {
        visiblePart.innerHTML = '';
      }
      for (let i = 0; i < config.numberSuggestions && i < suggestions.length; i++) {
        const option = document.createElement('option');
        option.value = suggestions[i].item;
        datalist.appendChild(option);
      }
    } else {searchForAddressInput(globals);}
  };

  // safari double form submit bug
  addressForm.onkeydown = (e) => {
    if (e.key === 'Enter') {
      datalist.innerHTML = '';
      searchForAddressInput(globals);
        return false;
    }
    return true;
  };
};
