import isMobile from './isMobile';
import showPolygon from './showPolygon';
import config from '../config';

export default (globals) => {
  const {fuse} = globals;
  const addressInput = document.querySelector('#address-input');
  const visiblePart = document.querySelector('#visible-part');
  visiblePart.innerHTML = '';
  const inputCityName = addressInput.value;
  const cityName = inputCityName.length > 0 ? fuse.search(`${inputCityName}`)[0].item : config.placeholder;

  if (isMobile()) {document.querySelector('#address-input').blur();}
  window.history.pushState('', '', `?wohnort=${cityName}`);
  addressInput.value = cityName;
  showPolygon(globals, cityName);
};
