import config from '../config';

export default (polygons, paths, map, type) => {
  polygons.forEach(polygon => polygon.setMap(null));
  if (Array.isArray(paths[0])) {
    paths.forEach((path, i) => {
      if (polygons[i]) {
        polygons[i].setPaths(path);
      } else {
        polygons.push(new google.maps.Polygon(type === 'INNER' ? config.polygonStyles.inner : config.polygonStyles.outer));
        polygons[i].setPaths(path);
      }
      polygons[i].setMap(map);
    });
  } else {
    if (polygons[0]) {
      polygons[0].setPaths(paths);
    } else {
      polygons.push(new google.maps.Polygon(type === 'INNER' ? config.polygonStyles.inner : config.polygonStyles.outer));
      polygons[0].setPaths(paths);
    }
    polygons[0].setMap(map);
  }
};
